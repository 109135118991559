<template>
  <div id="nf-register">
    <div class="contas" v-if="!showCNPJForm">
      <AccountsList />
    </div>

    <div class="new-account" v-else>
      <CNPJForm />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Register',
  components: {
    CNPJForm: () => import('@/modules/nfe/components/CNPJForm.vue'),
    AccountsList: () => import('@/modules/nfe/components/AccountsList.vue')
  },
  computed: {
    ...mapState('nfe', ['showCNPJForm'])
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss">
#nf-register {
  padding-bottom: 3rem;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  .title {
    margin: 2rem 0 4px;
  }
}
</style>
